import { Page } from "../utils/Pages";

function Render(): JSX.Element {
  return (
    <>
      {/* <img src={require("../img/Slide.jpg")} width="100%" /> */}
      <div className="base-page">
        <video width="100%" className="mb-5" autoPlay loop muted>
          <source src={require('../img/video_intro.mp4')} type="video/mp4" />
        </video>

        <h1>Centro Studio Medico</h1>

        <h2 style={{color: "#8aac1a"}}><b>OsteoNatural: Osteopatia, Posturologia, Fisioterapia, Ginnastica Medica</b></h2>

        <p>
          Il <b>Centro Studio Medico OsteoNatural del Dott. Luca Bongrazio</b> a <b>Pescara</b> tratta un' ampia varietà di disturbi, dolori e malesseri di origine disfunzionale, problematiche complesse croniche e acute.
          Grazie all'osteopatia e alla naturopatia è possibile risolvere molteplici problemi inerenti al benessere della persona.
        </p>

        <p>
          L'<b>osteopatia</b> viene definita come una medicina alternativa volta al ripristino dell'equilibrio generale del corpo, delle sue funzioni e del suo benessere, attraverso specifiche tecniche di manipolazione.
          Si basa sul presupposto che il sistema nervoso vegetativo svolga costantemente una autonoma azione di controllo dell'omeostasi corporea a tutti i livelli e che tale attività sia manifesta somaticamente.
          Essa ricerca l'equilibrio funzionale globale, pertanto si ritiene che la salute vada ricercata in un'armoniosa integrazione di corpo e mente.
        </p>

        <p>
          <b>La medicina osteopatica</b> <br />
          L'<b>osteopatia</b> è una disciplina sanitaria che ha lo scopo di stimolare l'innata capacità di auto-regolazione e auto-guarigione del corpo.
          Il metodo di intervento è riferito all'esclusivo utilizzo di specifiche tecniche manuali applicate a scopo diagnostico e terapeutico per la risoluzione delle "disfunzioni somatiche".
          L'efficacia della cura si realizza attraverso il recupero integrato degli equilibri funzionali statici e dinamici, con conseguente risoluzione dei sintomi algici.
          Rilevanza in ambito di medicina preventiva e interdisciplinare.
        </p>

        <p>
          La <b>naturopatia</b> è una disciplina bio-naturale che attraverso molteplici tecniche naturali, favorisce il mantenimento dello stato di salute e di benessere della persona, in base alle sue caratteristiche costituzionali.
          Il naturopata professionista, operando autonomamente rispetto alle figure sanitarie, fornisce una consulenza attraverso l'educazione sui principi dell'alimentazione naturale, stili di vita “secondo natura”, l'utilizzo di integratori fito-nutrizionali e quindi gioca un ruolo di primaria importanza nel campo della prevenzione attiva. 
        </p>

        <ul>
          <li>Terapia specifica di patologie congenite o acquisite del rachide (scoliosi)</li>
          <li>Riabilitazione post-traumatica/post-chirurgica</li>
          <li>Preparazione pre-parto/terapia post-parto</li>
          <li>Trattamento specifico cicatrici e aderenze</li>
          <li>Riequilibrio cranio-sacrale</li>
          <li>Riequilibrio posturo-occlusale</li>
          <li>Protocollo specifico per i colpi di frusta</li>
          <li>Trattamento cranio-sacrale in età neonatale</li>
          <li>Trattamento specifico viscerale per disfunzioni viscero-somatiche</li>
          <li>Trattamento specifico delle cefalee/emicranie cronico ricorrenti muscolo-tensive</li>
          <li>Trattamento della dismenorrea in correlazione a traumi sacrali</li>
          <li>Trattamento della coccigodine</li>
        </ul>

        <hr />

        <div>
          <h1>Testimonianze</h1>

          <div className="grid-container-5">
            <div className="p-3 pb-1 rounded-3" style={{backgroundColor: "#eeeeee"}}>
              <h3>Ho risolto alla grande</h3>
              <div className="d-flex" style={{color: "#d8b767"}}>
                <i className="bi bi-star-fill pe-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill ps-1"></i>
              </div>
              <p>I miei problemi di salute erano cervicali, ero contratto, insomma mi sentivo sempre male. Studio a Parma Università, quindi stando sui libri, mattina e sera, mi accorgevo che i fastidi raddoppiavano, uniti anche allo stress. Mi hanno consigliato di recarmi da un naturopata, osteopata. Applicando la terapia cranio sacrale e la fitoterapia finalmente ho risolto alla grande. Grazie Luca.</p>
              <p>- <b>GIANLUCA</b> 29 anni</p>
            </div>

            <div className="p-3 pb-1 rounded-3" style={{backgroundColor: "#eeeeee"}}>
              <h3>I miei disturbi si sono prima calmati e poi sono addirittura scomparsi</h3>
              <div className="d-flex" style={{color: "#d8b767"}}>
                <i className="bi bi-star-fill pe-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill ps-1"></i>
              </div>
              <p>Mi chiamo Matteo, vi racconto: avevo disturbi gastroenerologici, sentivo il mio stomaco brontolare tipo rigurgiti ed eruttazioni in continuazione, ero preoccupato e disperato per quello che mi stava accadendo. Iniziai il mio percorso recandomi in ospedale per effettuare una visita dal gastroenterologo e disse che dovevo effettuare una gastroscopia ed esami del sangue e fu tutto negativo. I miei disturbi erano sempre uguali dopo una terapia gastrenterologica. Mi sono rivolto ad un naturopata, osteopata, chinesiologo ed effettuando delle sedute ho risolto. I miei disturbi si sono prima calmati e poi addirittura sono scomparsi. Grazie Luca.</p>
              <p>- <b>MATTEO</b> 46 anni</p>
            </div>

            <div className="p-3 pb-1 rounded-3" style={{backgroundColor: "#eeeeee"}}>
              <h3>Le mie cefalee sono sparite</h3>
              <div className="d-flex" style={{color: "#d8b767"}}>
                <i className="bi bi-star-fill pe-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill px-1"></i>
                <i className="bi bi-star-fill ps-1"></i>
              </div>
              <p>Avevo problemi di cefalee notturni e diurni. Un amico mi ha consigliato un naturopata, osteopata, chinesiologo e mi sono rivolta a Luca. Effettuando delle sedute mi sentivo meglio e le mie cefalee sono sparite. Grazie Luca.</p>
              <p>- <b>PATRIZIA</b> 50 anni</p>
            </div>
          </div>
        </div>

        <hr />

        <iframe src="https://tally.so/embed/wzKa60?alignLeft=1&transparentBackground=1" loading="lazy" width="100%" height="500" title="Prenotati"></iframe>

        <hr />
      </div>
    </>
  );
}

const Home: Page = {
  name: "HOME",
  description: "Home page del sito.",
  path: "/",
  render: Render,
  showInNavbar: true,
}

export default Home;